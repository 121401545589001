import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import AuthClassicLayoutProfile from 'src/layouts/profile/classic';

// ----------------------------------------------------------------------

const RegisterProfilePage = lazy(() => import('src/pages/profile/register'));

// ----------------------------------------------------------------------

export const profileRoutes = [
  {
    path: 'profile',
    element: (
      <AuthGuard>
        <AuthClassicLayoutProfile>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AuthClassicLayoutProfile>
      </AuthGuard>
    ),
    children: [
      { element: <RegisterProfilePage />, index: true },
      { path: 'register', element: <RegisterProfilePage /> },
    ],
  },
];
