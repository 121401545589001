import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export default function SearchNotFound({ query, sx, ...other }) {
  if (query === '') {
    return (
      <Typography variant="body2" sx={sx}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {' '}
          <Iconify icon="gg:close-o" />
          Not found, please try something else
        </Stack>
      </Typography>
    );
  }

  return (
    <Typography variant="body2" sx={sx}>
      {query ? (
        <Stack direction="row" alignItems="center" spacing={1}>
          {' '}
          <Iconify icon="fluent:arrow-enter-left-24-regular" />
          Press enter to apply
        </Stack>
      ) : (
        'Please enter keywords'
      )}
    </Typography>
  );
}

SearchNotFound.propTypes = {
  query: PropTypes.string,
  sx: PropTypes.object,
};
