import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

export const EmailFilterContext = createContext();

export const EmailFilterProvider = ({ children }) => {
  const [filterCriteria, setFilterCriteria] = useState('');

  const value = useMemo(() => ({ filterCriteria, setFilterCriteria }), [filterCriteria]);

  return <EmailFilterContext.Provider value={value}>{children}</EmailFilterContext.Provider>;
};

EmailFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
