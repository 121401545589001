import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';

// utils
// eslint-disable-next-line
import axios, { endpoints } from 'src/utils/axios';
import { useRouter } from 'src/routes/hooks';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession, refreshToken } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  from: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    console.log(action.payload);
    return {
      ...state,
      user: action.payload.user,
      from: action.payload.from,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
      from: action.payload.from,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
      from: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const router = useRouter();

  const logout = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_HOST_API}/api/auth/logout`, {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        setSession(null); // Clear session on logout
        dispatch({ type: 'LOGOUT' });
        router.replace('/login'); // Redirect to login
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [router]);

  const initialize = useCallback(async () => {
    try {
      let accessToken = sessionStorage.getItem(STORAGE_KEY);
      let refresh_token = sessionStorage.getItem('refreshToken');
      let returnUrl = null;
      let email = null;

      await fetch(`${process.env.REACT_APP_HOST_API}/api/auth/session`, {
        credentials: 'include',
      })
        .then((response) => {
          if (response.ok) {
            // Check if the status code is 200-299
            return response.json(); // Parses the body of the response as JSON
          }
          throw new Error('Network response was not ok.');
        })
        .then((data) => {
          console.log(data);
          if (data.returnUrl) {
            returnUrl = data.returnUrl;
          }
          accessToken = data.accessToken;
          refresh_token = data.refresh_token;
          email = data.email;
        })
        .catch((error) => {
          console.error('There has been a problem with your fetch operation:', error);
        });

      if (accessToken) {
        const isExpired = !isValidToken(accessToken);
        console.log('isExpired', isExpired);
        if (isExpired) {
          accessToken = await refreshToken(email, logout); // Refresh token if expired
          if (!accessToken) return; // Exit if refresh failed
        }

        setSession(accessToken, refresh_token);

        const response = await axios.get(endpoints.auth.me, {
          withCredentials: true, // Include cookies with the request
        });

        const { user } = response.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
        // http://localhost:3030/profile/register
        if (returnUrl !== null) {
          router.replace(returnUrl);
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('entered in init');
    initialize();
  }, [initialize]);

  // // LOGOUT
  // const logout = useCallback(async () => {
  //   console.log('logout');
  //   const response = await fetch(`${process.env.REACT_APP_HOST_API}/api/auth/logout`, {
  //     method: 'POST',
  //     credentials: 'include',
  //   });
  //   if (response.ok) {
  //     setSession(null);
  //     dispatch({
  //       type: 'LOGOUT',
  //     });
  //   } else {
  //     console.error('Logout failed');
  //   }
  // }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      logout,
    }),
    [logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
