import useSWR from 'swr';
// import keyBy from 'lodash/keyBy';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';

export function useSearchEmails(query, email) {
  const URL = email && [endpoints.search.global, { params: { query, email } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      searchResults: data?.results || [],
      searchLoading: isLoading,
      searchError: error,
      searchValidating: isValidating,
      searchEmpty: !isLoading && !data?.results.length,
    }),
    [data?.results, error, isLoading, isValidating]
  );

  return memoizedValue;
}
