// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './reducers/counter.reducer';
import mailReducer from './reducers/mail.reducer';

export const store = configureStore({
  reducer: {
    // Add reducers here
    counter: counterReducer,
    mail: mailReducer,
  },
});
