import Cookies from 'js-cookie';

// routes
import { paths } from 'src/routes/paths';
// utils
// eslint-disable-next-line
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = async (accessToken) => {
  if (!accessToken) {
    return false;
  }

  try {
    // Send a request to Google's Token Info API to validate the token
    const response = await axios.get('https://www.googleapis.com/oauth2/v3/tokeninfo', {
      params: {
        access_token: accessToken,
      },
    });

    console.log(response);

    // If the token is valid, the response will contain the token details
    return true; // Token is valid
  } catch (error) {
    console.error('Error validating token:', error);
    return false; // Token is invalid or expired
  }
};
// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

export const refreshTokenF = async () => {
  const refresh_token = sessionStorage.getItem('refreshToken');

  if (!refresh_token) {
    console.error('No refresh token found');
    return null;
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_HOST_API}/api/auth/refresh`, {
      refresh_token,
    });

    console.log('response token', response);

    const { accessToken } = response.data;
    setSession(accessToken); // Update session with new access token
    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};

// ----------------------------------------------------------------------

export const setSession = (accessToken, refresh_token = null) => {
  if (accessToken) {
    // Store accessToken in sessionStorage
    sessionStorage.setItem('accessToken', accessToken);

    // Store refreshToken if provided
    if (refresh_token) {
      sessionStorage.setItem('refreshToken', refresh_token);
    }

    console.log('accessToken', accessToken);

    // Store accessToken in cookies (for use in SocketProvider)
    // Cookies.set('auth_tokens', JSON.stringify({ access_token: accessToken }), {
    //   path: '/', // Set cookie to be accessible from all paths
    //   expires: 7, // Set expiration time (e.g., 7 days)
    //   secure: true, // Set true if you're using HTTPS
    //   sameSite: 'strict', // Set to 'strict' to prevent CSRF
    // });

    // console.log('done', Cookies.get());

    // Store tokens in cookies (for use in SocketProvider)
    const tokenData = JSON.stringify({
      access_token: accessToken,
      refresh_token: refresh_token || sessionStorage.getItem('refreshToken'),
    });

    Cookies.set('cookie', tokenData, {
      path: '/',
      expires: 7,
      secure: false, // Set to false for localhost
      sameSite: 'strict',
    });

    console.log('Test Cookie:', Cookies.get('cookie'));

    // Set Authorization header for axios
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // Remove accessToken from sessionStorage and cookies
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');

    Cookies.remove('cookie');

    delete axios.defaults.headers.common.Authorization;
  }
};

export const refreshToken = async (email, logout) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_HOST_API}/api/auth/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ email }), // Send email in the body
    });

    if (!response.ok) throw new Error('Failed to refresh token');

    const { access_token, refresh_token } = await response.json(); // Expect access and refresh tokens in the response
    setSession(access_token, refresh_token); // Update session with both tokens

    return access_token; // Return the new access token
  } catch (error) {
    console.error('Error refreshing token:', error);
    if (logout) logout(); // Log out user if refresh fails
    return null;
  }
};
