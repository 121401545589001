// src/features/mail/mailSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async action for fetching mails
export const fetchMails = createAsyncThunk('mail/fetchMails', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_HOST_API}/api/mail/list?labelId=all&user=charlie.apcher@gmail.com&searchWords=`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const mailSlice = createSlice({
  name: 'mail',
  initialState: {
    mails: {},
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    // Define other non-async actions here if necessary
    setMails: (state, action) => {
      state.mails = action.payload;
      state.status = 'succeeded';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Assuming the API returns an object of mails
        state.mails = action.payload;
      })
      .addCase(fetchMails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

// Export actions for use in components
// export const { } = mailSlice.actions; // Add any synchronous actions if you have them

export default mailSlice.reducer;
