import PropTypes from 'prop-types';
import { useCallback, useState, useContext, memo } from 'react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import { useAuthContext } from 'src/auth/hooks';

import { useDebounce } from 'src/hooks/use-debounce';

import { useSearchEmails } from 'src/api/search';

import { SearchContext } from 'src/layouts/dashboard/context/searchProvider';

import { EmailFilterContext } from 'src/layouts/dashboard/context/emailProvider';

import EmailSearch from './email-search';

function AutoCompleteSearchBar() {
  // const [searchQuery, setSearchQuery] = useState('');

  const { searchQuery, setSearchQuery } = useContext(SearchContext);
  const { setFilterCriteria } = useContext(EmailFilterContext);
  const { user } = useAuthContext();

  const handleSearch = useCallback(
    (inputValue) => {
      setSearchQuery(inputValue);
    },
    [setSearchQuery]
  );

  const handleOptionSelect = useCallback(
    (selectedOption) => {
      console.log('selectedOption:', selectedOption);
      setFilterCriteria(selectedOption); // Assuming `selectedOption` has an `id` to filter emails
    },
    [setFilterCriteria]
  );

  const debouncedQuery = useDebounce(searchQuery);

  const { searchResults, searchLoading } = useSearchEmails(debouncedQuery, user.email);

  // console.log(searchResults, searchLoading);

  return (
    <div>
      <EmailSearch
        query={debouncedQuery}
        results={searchResults}
        onSearch={handleSearch}
        onSelect={handleOptionSelect}
        hrefItem={() => console.log('entered')}
        loading={searchLoading}
        setFilterCriteria={setFilterCriteria}
      />
    </div>
  );
}

export default memo(AutoCompleteSearchBar);
